<template>
  <div class="executed-list-wrapper">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="executed-item-box" @click="getPreCheckStatus(item)" v-for="item in list" :key="item.id">
        <div class="item-box-title">
          <span>{{item.deviceName+ ' ( '+item.deviceNo+' ) '}}</span>
        </div>
        <div class="item-box-info">
          <div class="info-left">
            <i class="iconfont icondianjianguanli"></i>
          </div>
          <div class="info-center">
            <span class="title">{{item.planName}}</span>
            <div class="detail">
              <span>保养项目：{{item.maintainItemName}}</span>
              <span>执行时间：{{item.maintainStartTime +' - '+ item.maintainEndTime}}</span>
            </div>
          </div>
          <div class="info-right">
            <span :style="{'color':filterColor(item.orderStatus)  }">{{item.orderStatus | filterStatus}}</span>
            <i class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"></i>
          </div>
        </div>

      </div>
    </van-list>
    <Dialog :show="show" :info="dialogInfo" @handleCloseDialogClick="handleCloseDialogClick" @handleQueryDialogClick="handleQueryDialogClick" />
    <Dialog1 :show="show1" :info="dialogInfo1" @handleQueryDialogClick="handleQueryDialogClick1" />
  </div>
</template>

<script>
import Vue from 'vue'
import Dialog from '@/components/Dialog'
import Dialog1 from './Dialog'

import { List, Toast } from 'vant'
import { getMaintainList, receiveMaintainOrder } from '@/api/maintain'

Vue.use(List)
Vue.use(Toast)

export default {
  name: 'ExecutedList',
  components: {
    Dialog,
    Dialog1,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      params: {
        page: 0,
        size: 10,
      },
      show: false,
      dialogInfo: {
        question: '接单后其他人无法接单此任务，是否开始保养？',
        cancerTxt: '取消',
        queryTxt: '确定',
      },
      show1: false,

      dialogInfo1: {
        question:
          '不是我的工单？对工单内容有疑问？请联系管理员重新派单或更改任务',
      },
      currentMaintainId: 0,
    }
  },
  filters: {
    filterStatus(val) {
      var statusList = {
        1: '待接单',
        2: '待执行',
        3: '待审核',
        4: '审核通过',
        5: '审核不通过',
        '-2': '已过期',
      }
      return statusList[val]
    },

  },
  methods: {
    /**
     * 列表
     */
    getMaintainList() {

      if (this.params.page === 0) {
        this.list = []
      }
      Object.assign(this.params, this.$parent.params)
      getMaintainList(this.params).then((res) => {
        const content = res.details.content
        content.forEach((item) => {
          this.list.push(item)
        })
        this.loading = false
        if (this.list.length >= res.details.totalElements || content.length == '0') {
          this.finished = true
          this.loading = true
        } else {
          this.params.page = this.params.page + 1
        }

      })
    },
    /**
     * 进入前确认接口
     */
    getPreCheckStatus(item) {
      this.currentMaintainId = item.id
      if (item.orderStatus === 1) {
        this.show = true
      }
      else {
        this.$router.push({
          path: '/maintainInfo/info',
          query: {
            id: this.currentMaintainId,
          },
        })
      }

    },
    handleCloseDialogClick() {
      this.show = false
    },
    handleQueryDialogClick1() {
      this.show1 = false
    },

    handleQueryDialogClick() {
      receiveMaintainOrder(this.currentMaintainId)
        .then((res) => {
          if (res.retCode == '1') {
            this.$router.push({
              path: '/maintainInfo/info',
              query: {
                id: this.currentMaintainId,
              },
            })
            this.show = false
          } else {
            this.show = false

            Toast(res.retMsg);

          }
        })
        .catch((err) => {
          this.show = false
        })
    },
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        this.getMaintainList()
      }, 500)
    },
    filterColor(val) {
      var statusList = {
        1: '#4A8CFF',
        2: '#4A8CFF',
        3: '#333333',
        4: '#01A439',
        5: '#FF0101',
        '-2': '#CCCCCC',
      }
      return statusList[val]
    },
  },
  watch: {
    '$parent.params': {
      handler: function (val) {
        this.params.page = 0
        this.finished = false
        this.getMaintainList()
      },
      deep: true
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.executed-list-wrapper {
  box-sizing: border-box;
  padding: 1rem 0.5rem;
  background-color: #f1f1f1;
  .executed-item-box {
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 0.3rem;
    .item-box-title {
      padding: 0.3rem 0.5rem;
      span {
        color: #999999;
        font-size: 0.7rem;
        font-weight: bold;
      }
      border-bottom: 1px solid #ebedf0;
    }
    .item-box-info {
      padding: 0.3rem 0rem;
      display: flex;
      flex-direction: row;
      align-items: top;
      .info-left {
        margin: 0 0.55rem;
        i {
          color: #409eff;
          font-size: 1rem;
        }
      }
      .info-center {
        width: 0;
        flex: 1;
        text-align: justify;
        text-justify: newspaper;
        word-break: break-all;
        .title {
          display: block;
          color: #333333;
          font-size: 0.68rem;
          font-weight: bold;
          margin: 0.4rem 0;
        }
        .detail {
          margin: 0.2rem 0;
          span {
            color: #999999;
            font-size: 0.4rem;
            display: block;
            margin: 0.3rem 0;
          }
        }
      }
      .info-right {
        padding: 0 0.5rem;
        span {
          font-size: 0.5rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
