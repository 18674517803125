<template>
  <div class="check-wrapper">
    <div class="tab-wrapper">
      <van-dropdown-menu>
        <van-dropdown-item v-model="params.deviceType" :options="deviceTypeList" @change='changeDevice' />
        <van-dropdown-item v-model="params.deviceId" :options="deviceList" />
        <van-dropdown-item v-model="params.orderStatus" :options="orderStatusList" />
      </van-dropdown-menu>
    </div>
    <div class="content-wrapper">
      <ExecutedList />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Tab, Tabs, DropdownMenu, DropdownItem } from 'vant';
import ExecutedList from './components/ExecutedList'
import 'vant/lib/tab/style'
import 'vant/lib/tabs/style'
import 'vant/lib/dropdown-menu/style'
import 'vant/lib/dropdown-item/style'
import { deviceDict, getDeviceTypeTree } from '@/api/maintain'

Vue.use(Tab).use(Tabs).use(DropdownMenu).use(DropdownItem);
export default {
  name: 'Check',
  components: {
    ExecutedList,
  },
  data() {
    return {
      tabActive: 0,
      params: {
        deviceType: "",
        deviceId: "",
        orderStatus: '',

      },

      deviceTypeList: [
        { text: '设备类型', value: '' },
      ],
      deviceTypeKey: {},
      deviceList: [
        { text: '设备名称', value: '' },
      ],
      orderStatusList: [
        { text: '工单状态', value: '' },
        { text: '已过期', value: '-2' },
        { text: '待接单', value: '1' },
        { text: '待执行', value: '2' },
        { text: '待审核', value: '3' },
        { text: '审核通过', value: '4' },
        { text: '审核不通过', value: '5' },
      ],

      deviceTypes: []

    }
  },
  created() {
    this.deviceDict()
    this._getDeviceTypeTree()
  },
  methods: {
    deviceDict() {
      this.deviceList = [{ text: '设备名称', value: '' }]
      this.params.deviceId = ''
      deviceDict(1).then((res) => {
        res.map(item => {
          this.deviceTypeList.push({ text: item.label, value: item.status },)
          this.deviceTypeKey[item.status] = item.label
        })


      })
    },
    _getDeviceTypeTree() {
      getDeviceTypeTree().then((res) => {
        this.deviceTypes = res.details.content

      })
    },

    changeDevice(val) {
      this.deviceList = [{ text: '设备名称', value: '' }]
      this.params.deviceId = ''
      let restul = this.deviceTypes.find(item => {
        return item.status == val
      })
      restul.deviceChildren.map(item => {
        this.deviceList.push({
          text: item.deviceName + ' ( ' + item.deviceNo + ' )', value: item.id
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.check-wrapper {
  @include wh(100%, 100%);
  background-color: #f1f1f1;
  box-sizing: border-box;
  padding-top: 44px;
  position: relative;
  .tab-wrapper {
    @include wh(100%, 44px);
    position: fixed;
    left: 0;
    top: 0rem;
    /deep/.van-tab {
      .van-tab__text {
        font-size: 0.85rem;
        color: #333333;
      }
      &.van-tab--active {
        .van-tab__text {
          color: #4a8cff;
        }
      }
    }
    /deep/.van-tabs__line {
      background-color: #4a8cff;
    }
  }
  .content-wrapper {
  }
}
</style>
